import { EventEmitter } from "events"
import { BoothWorld } from "./BoothWorld"

export class OnionWorld extends BoothWorld {
  public readonly id = "onion"

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<OnionWorld> {
    const opts = await BoothWorld.initScene("ONION", "03_Onion")
    return new OnionWorld(events, canvas, opts)
  }
}
