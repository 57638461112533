import { useLocation } from "@reach/router"

export type WorldId = "entrance" | StageType | "other"

export const languages = ["ja", "en"] as const
export type Language = typeof languages[number]

export type LanguageSwitcherData = {
  link: string
  langKey: string
  selected: boolean
}

/**
 * ページ遷移に使うデータ
 */
export type MovePageParams = {
  url: string // 遷移先のURL
  title: string // タイトル
  description?: string // 遷移先についての説明文
  label?: string // 入場ボタンの文言。デフォルトでは "入場する"
  eventName?: string // eventを発火する場合はその名前
}

/** Gatsbyのrouterから渡されるwindow.location情報 */
export type Location = ReturnType<typeof useLocation>

export type PageProps = {
  location: Location
}

export const stageTypes = [
  "radish",
  "carrot",
  "onion",
  "potato",
  "turnip",
  "garlic",
] as const
export type StageType = typeof stageTypes[number]

export function isStageType(x: string): x is StageType {
  return stageTypes.includes(x as StageType)
}
