import { EventEmitter } from "events"

import * as THREE from "three"
import CameraControls from "./vendor/camera-controls"
CameraControls.install({ THREE })

import type { World } from "./worlds/World"
import { EmptyWorld } from "./worlds/EmptyWorld"
import { CarrotWorld } from "./worlds/CarrotWorld"
import { GarlicWorld } from "./worlds/GarlicWorld"
import { OnionWorld } from "./worlds/OnionWorld"
import { PotatoWorld } from "./worlds/PotatoWorld"
import { RadishWorld } from "./worlds/RadishWorld"
import { TurnipWorld } from "./worlds/TurnipWorld"

import { Clock, WebGLRenderer } from "three"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { TransitionShader } from "./TransitionShader"

import TWEEN from "@tweenjs/tween.js"
import { AudioPlayer } from "./AudioPlayer"
import { TextMesh } from "./TextMesh"
import { MouseInfo, MouseWatcher } from "./MouseWatcher"
import { WorldId } from "../commonTypes"
import { Loader } from "./Loader"
import { BoothWorld } from "./worlds/BoothWorld"

export type MoveDir = "left" | "right" | "up" | "down"

export class Game {
  // private input = new Input(this)
  private world: World
  private composer: EffectComposer
  private transitionPass = new ShaderPass(TransitionShader)
  private clock = new Clock()
  private isMoving: Record<MoveDir, boolean> = {
    left: false,
    right: false,
    up: false,
    down: false,
  }
  private audioPlayer: AudioPlayer
  private mouseWatcher: MouseWatcher
  private enabled = false

  private constructor(
    private events: EventEmitter,
    private canvas: HTMLCanvasElement,
    private renderer: WebGLRenderer,
    private worlds: World[],
    worldId: WorldId
  ) {
    this.world = worlds[0]
    this.world.setEnabled(true)

    this.composer = this.initComposer()

    window.addEventListener("resize", this.resize)
    window.addEventListener("keydown", this.onKeyDown)
    window.addEventListener("keyup", this.onKeyUp)
    document.addEventListener("visibilitychange", this.onVisibilityChange)

    this.mouseWatcher = new MouseWatcher(this.canvas)
    this.mouseWatcher.on("click", this.onClick)
    this.mouseWatcher.on("mousemove", this.onMouseMove)

    // 現在のページによって描画on/offを切り替える
    worldId !== "other" ? this.enable() : this.disable()

    this.resize()
    this.start()

    this.audioPlayer = new AudioPlayer(events)

    // HTML側からのイベントハンドラーを設定
    this.events.on("changeWorld", this.changeWorld)
    this.events.on("stageSelect", this.stageSelect)
    this.events.on("moveToVendor", this.moveToVendor)
    this.events.on("openVendor", this.disable)
    this.events.on("leaveVendorDetail", this.enable)

    this.events.emit("loaded", true)
  }

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement,
    worldId: WorldId
  ): Promise<Game> {
    Loader.init()

    const renderer = new WebGLRenderer({ canvas, alpha: true })
    renderer.autoClear = false
    renderer.shadowMap.enabled = true
    renderer.setSize(canvas.offsetWidth, canvas.offsetHeight)
    // renderer.setPixelRatio(window.devicePixelRatio)

    TextMesh.setIsWebGL2(renderer.capabilities.isWebGL2)
    TextMesh.preload()

    const worlds = [await Game.initWorldForId(worldId, events, canvas)]

    return new Game(events, canvas, renderer, worlds, worldId)
  }

  static initWorldForId(
    worldId: WorldId,
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<World> {
    return worldId === "carrot"
      ? CarrotWorld.init(events, canvas)
      : worldId === "garlic"
      ? GarlicWorld.init(events, canvas)
      : worldId === "onion"
      ? OnionWorld.init(events, canvas)
      : worldId === "potato"
      ? PotatoWorld.init(events, canvas)
      : worldId === "radish"
      ? RadishWorld.init(events, canvas)
      : worldId === "turnip"
      ? TurnipWorld.init(events, canvas)
      : EmptyWorld.init(canvas)
  }

  initComposer() {
    const composer = new EffectComposer(this.renderer)
    composer.renderer.autoClear = false

    this.transitionPass.uniforms["tex1"].value = this.world.renderTarget.texture
    this.transitionPass.uniforms["tex2"].value = this.world.renderTarget.texture
    composer.addPass(this.transitionPass)

    const fxaaPass = new ShaderPass(FXAAShader)
    const pixelRatio = this.renderer.getPixelRatio()
    fxaaPass.material.uniforms["resolution"].value.x =
      1 / (this.canvas.offsetWidth * pixelRatio)
    fxaaPass.material.uniforms["resolution"].value.y =
      1 / (this.canvas.offsetHeight * pixelRatio)
    composer.addPass(fxaaPass)
    composer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight)

    return composer
  }

  private resize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    this.canvas.width = width
    this.canvas.height = height
    this.worlds.forEach(w => w.resize(width, height))
    this.renderer.setSize(width, height)
    this.composer.setSize(width, height)
  }

  private onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.world.escape()
    }

    // 隠しコマンド キャプチャ用にUIを隠す
    if (e.ctrlKey && e.shiftKey && (e.key === "?" || e.key === "/")) {
      const hidden = this.canvas.style.zIndex === "-1"
      this.canvas.style.zIndex = hidden ? "9999999999" : "-1"
    }

    if (e.key === "ArrowLeft" || e.key === "a") {
      this.world.setMoving("left", true)
    } else if (e.key === "ArrowRight" || e.key === "d") {
      this.world.setMoving("right", true)
    } else if (e.key === "ArrowUp" || e.key === "w") {
      this.world.setMoving("up", true)
    } else if (e.key === "ArrowDown" || e.key === "s") {
      this.world.setMoving("down", true)
    }
  }

  private onKeyUp = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft" || e.key === "a") {
      this.world.setMoving("left", false)
    } else if (e.key === "ArrowRight" || e.key === "d") {
      this.world.setMoving("right", false)
    } else if (e.key === "ArrowUp" || e.key === "w") {
      this.world.setMoving("up", false)
    } else if (e.key === "ArrowDown" || e.key === "s") {
      this.world.setMoving("down", false)
    }
  }

  private onVisibilityChange = () => {
    if (document.visibilityState !== "visible") {
      this.world.resetMoving()
    }
  }

  private onMouseMove = ({ x, y, w, h }: MouseInfo) => {
    // -1〜+1の範囲で現在のマウス座標を登録する
    this.world.onMouseMove((x / w) * 2 - 1, -(y / h) * 2 + 1)
  }

  private onClick = ({ x, y, w, h, isRight }: MouseInfo) => {
    // -1〜+1の範囲で現在のマウス座標を登録する
    this.world.onClick((x / w) * 2 - 1, -(y / h) * 2 + 1)
  }

  dispose() {
    this.worlds.forEach(w => w.dispose())

    window.removeEventListener("resize", this.resize)
    window.removeEventListener("keydown", this.onKeyDown)
    window.removeEventListener("keyup", this.onKeyUp)
    document.removeEventListener("visibilitychange", this.onVisibilityChange)
    this.mouseWatcher.dispose()

    this.events.off("changeWorld", this.changeWorld)
    this.events.off("stageSelect", this.stageSelect)
    this.events.off("moveToVendor", this.moveToVendor)
    this.events.off("openVendor", this.disable)
    this.events.off("leaveVendorDetail", this.enable)

    // this.input.dispose()
  }

  start(): void {
    this.loop()
    this.world.start()
  }

  loop = () => {
    requestAnimationFrame(this.loop)
    if (!this.enabled) {
      return
    }

    TWEEN.update()

    // Move camera
    const deltaTime = this.clock.getDelta()
    this.world.move(deltaTime)

    this.renderer.clear()
    this.composer.renderer.clear()

    this.worlds.forEach(w => {
      w.loop()
      this.renderer.setRenderTarget(w.renderTarget)
      this.renderer.clear()
      w.render(this.renderer)
    })

    this.composer.render()
  }

  changeWorld = async (worldId: WorldId) => {
    const prevWorldId = this.world.id

    // ローディング画面を表示しつつ、現在のworldを抜ける
    MouseWatcher.isEnabled = false
    this.events.emit("loaded", false, worldId)

    await this.world.leaveWorld(worldId)
    this.world.setEnabled(false)

    // 新しいワールドを作成
    this.world = await Game.initWorldForId(worldId, this.events, this.canvas)
    this.worlds = [this.world]

    this.transitionPass.uniforms["tex1"].value = this.world.renderTarget.texture
    this.transitionPass.uniforms["tex2"].value = this.world.renderTarget.texture

    // 初回描画でテクスチャロードに時間がかかるのでちょっと待つ
    this.world.setEnabled(true)
    await new Promise(o => setTimeout(o, 100))

    // ローディング画面を隠しつつ、新しいワールドを表示
    this.events.emit("loaded", true, worldId)
    await this.world.enterWorld(prevWorldId)

    MouseWatcher.isEnabled = true

    this.events.emit("worldChanged", worldId)
  }

  stageSelect = async (dst: string) => {
    if (dst === "entrance") {
      await this.changeWorldIfneeded("entrance")
    } else if (dst === "carrot") {
      await this.changeWorldIfneeded("carrot")
    } else if (dst === "radish") {
      await this.changeWorldIfneeded("radish")
    } else if (dst === "onion") {
      await this.changeWorldIfneeded("onion")
    } else if (dst === "garlic") {
      await this.changeWorldIfneeded("garlic")
    } else if (dst === "turnip") {
      await this.changeWorldIfneeded("turnip")
    } else if (dst === "potato") {
      await this.changeWorldIfneeded("potato")
    }
  }

  async changeWorldIfneeded(dst: WorldId) {
    if (dst !== this.world.id) {
      await this.changeWorld(dst)
    }
  }

  setIsMoving(dir: MoveDir, isMoving: boolean): void {
    this.world.setMoving(dir, isMoving)
  }

  moveToVendor = async (id: number): Promise<void> => {
    // TODO: ワールド移動処理
    // await this.changeWorldIfneeded("carrot")

    ;(this.world as BoothWorld).moveToVendor(id.toString())
  }

  enable = (): void => {
    this.enabled = true
    this.canvas.style.display = "block"
    this.events.emit("playSE", `se/14_${Math.floor(Math.random() * 8)}`)
  }

  disable = (): void => {
    this.enabled = false
    this.canvas.style.display = "none"
    this.events.emit("playSE", `se/13_${Math.floor(Math.random() * 10)}`)
  }
}
