import { Box3, Mesh, MeshStandardMaterial, Sphere, Texture } from "three"
import { Loader } from "./Loader"

const bgmList = [
  "booth_world/dummy",
  "booth_world/dummy2",
  // "booth_world/bgm_01_entrance_1",
  // "booth_world/bgm_01_going_home__second_guess",
  // "booth_world/bgm_01_lets_get_a_video",
  // "booth_world/bgm_01_rehearsing_heat_1",
  // "booth_world/bgm_01_top_soil_1",
  // "booth_world/bgm_02_nocturnal_bloom_1",
  // "booth_world/bgm_03_botanique_1",
  // "booth_world/bgm_05_four_glass_steps_1",
  // "booth_world/bgm_05_morning_frost_over_paddocks_with_live_stock,_myers_flat,_victoria",
  // "booth_world/bgm_05_one_night_at_the_pool_1",
  // "booth_world/bgm_05_soft_coral_1",
  // "booth_world/bgm_05_sullo_slide_casca_piano_1",
  // "booth_world/bgm_06_la_voce_delle_perle_che_si_svegliano_di_notte_1",
  // "booth_world/bgm_08_three_years_later_1",
  // "booth_world/bgm_09_middlemist_1",
  // "booth_world/bgm_09_windy_ponies__when_you_try_to_laugh,_but_all_you_can_do_is_cry",
  // "booth_world/bgm_10_fua_fua",
  // "booth_world/bgm_11_le_gesta_del_vento_in_salita_1",
  // "booth_world/bgm_15_lieber_markus_1",
  // "booth_world/bgm_25_down_the_river_1",
]

/**
 * ランダムなBGMの名前を取得する。
 * 同じBGMが2回連続で鳴らないよう、一つ前のBGMを記憶している。
 */
let lastBGM: string | undefined
export function getRandomBGM(): string {
  let newBGM
  do {
    newBGM = bgmList[Math.floor(Math.random() * bgmList.length)]
  } while (newBGM === lastBGM)

  lastBGM = newBGM
  return newBGM
}

const bundlesUrl = "https://d3lm2i23uzukol.cloudfront.net/bundles"

export async function getTextureRecord(
  name: string,
  stageName: string
): Promise<Record<string, Texture>> {
  const model = await Loader.loadGLTF(
    `${bundlesUrl}/${stageName}/${name}_bundle.glb`,
    undefined
  )
  const textures: Record<string, Texture> = {}
  model.traverse(o => {
    const m = o as Mesh
    if (m.isMesh) {
      const mat = m.material as MeshStandardMaterial
      if (mat.map) {
        const [_, id] = mat.map.name.split("_")
        textures[id] = mat.map
      }
    }
  })
  return textures
}

export async function getTexturesRecord(
  name: string,
  stageName: string
): Promise<Record<string, Texture[]>> {
  const model = await Loader.loadGLTF(
    `${bundlesUrl}/${stageName}/${name}_bundle.glb`,
    undefined
  )
  const textures: Record<string, Texture[]> = {}
  model.traverse(o => {
    const m = o as Mesh
    if (m.isMesh) {
      const mat = m.material as MeshStandardMaterial
      if (mat.map) {
        const [_, id, indexStr] = mat.map.name.split("_")
        const index = parseInt(indexStr, 10)

        if (textures[id] === undefined) {
          textures[id] = []
        }
        textures[id][index] = mat.map
      }
    }
  })
  return textures
}

export function getBoundingSphereOfMesh(mesh: Mesh): Sphere {
  const box = new Box3()
  box.setFromObject(mesh)
  const s = new Sphere()
  box.getBoundingSphere(s)
  return s
}
