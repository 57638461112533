import {
  Camera,
  DoubleSide,
  IUniform,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  ShaderMaterial,
  SphereGeometry,
  Texture,
} from "three"
import { Loader } from "../../Loader"
import { GameState, SceneObject } from "../../types"
import { BoothLayout } from "./csv"

const PLANE_SIZE = 6.338
const PLANE_GEOMETRY = new PlaneBufferGeometry(PLANE_SIZE, PLANE_SIZE) // 4:3比率

const FPS = 6.0
const IMAGES = 3.0

const DEG2RAD = Math.PI / 180

const vertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const fragmentShader = `
    varying vec2 vUv;
    uniform float time;
    uniform sampler2D sprites;
    uniform bool isPlaying;

    const float FPS = ${FPS.toFixed(2)};
    const float IMAGES = ${IMAGES.toFixed(2)};
    const float DIM = 2.; // セル数

    vec4 drawCell(float index) {
        vec2 pos = vec2(
          mod(index, DIM),
          (DIM - 1.) - floor(index / DIM)
        );
        vec2 uv = (vUv + pos) / DIM;
        return texture2D(sprites, uv);
    }

    void main() {
        if (isPlaying) {
            float index = mod(floor(time * FPS), 2. * IMAGES - 2.);
            index = (IMAGES - 1.) - abs(index - (IMAGES - 1.));
            gl_FragColor = drawCell(index);
        } else {
            gl_FragColor = drawCell(1.); // 無回転のセル
        }
    }
    `

// デバッグ用 あとで消す
const debugMat = new MeshBasicMaterial({
  color: 0xff0000,
  side: DoubleSide,
})
const uvMat = new ShaderMaterial({
  vertexShader,
  fragmentShader: `
  varying vec2 vUv;
  void main() {
      gl_FragColor = vec4(vUv, 1, 1);
  }
  `.trim(),
})

export class Haribote implements SceneObject {
  private startTime = Date.now() / 1000
  private isPlaying = false

  private constructor(
    public readonly mesh: Mesh,
    public readonly compo: Object3D,
    private uniforms: Record<string, IUniform>
  ) {}

  static async init(
    stageFileDir: string,
    data: BoothLayout,
    board: Object3D,
    wallTextures: Texture[],
    tableTextures: Texture[]
  ): Promise<Haribote> {
    const root = new Mesh()
    root.name = "Haribote"

    // ハリボテ
    const hariboteTexture = Loader.loadTexture(
      `/stages/${stageFileDir}/sprite/${data.angle}_Booth_${data.material}_${data.composition}.png`
    )
    const uniforms = {
      time: { value: 0 },
      sprites: { value: hariboteTexture },
      isPlaying: { value: false },
    }
    const hariboteMaterial = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader,
      fragmentShader,
      transparent: true,
      depthTest: false,
    })
    const haribote = new Mesh(PLANE_GEOMETRY, hariboteMaterial)
    haribote.position.set(0, -PLANE_SIZE * 0.125, -0.05)
    root.add(haribote)

    // boardのマテリアルを貼り直す
    for (const c of board.children) {
      const m = c.name.match(/(Wall|Table)_(\d)/)
      if (!m) {
        continue
      }

      const index = parseInt(m[2]) - 1
      const texture = (m[1] === "Wall" ? wallTextures : tableTextures)[index]

      if (texture) {
        texture.repeat.y = -1 // TODO: UVの向きが直ったら消す
        ;(c as Mesh).material = new MeshBasicMaterial({
          map: texture,
        })
      }
      // ;(c as Mesh).material = uvMat
    }

    return new Haribote(root, board, uniforms)
  }

  update(game: GameState, camera: Camera) {
    const time = Date.now() / 1000 - this.startTime
    this.uniforms["time"].value = time

    if (this.isPlaying) {
      let index = Math.floor(time * FPS) % (2 * IMAGES - 2)
      index = IMAGES - 1 - Math.abs(index - (IMAGES - 1))
      const unit = 10
      const angle = [-unit, 0, unit][index] * DEG2RAD * 0.1

      // 天板、壁面を回転させる
      this.compo.rotation.y = angle
    } else {
      this.compo.rotation.y = 0
    }

    // メッシュ全体をカメラに向かせる
    this.mesh.rotation.copy(camera.rotation)
  }

  dispose() {
    this.mesh.remove()
  }

  toggleAnimation(play: boolean): void {
    this.isPlaying = play
    this.uniforms["isPlaying"].value = play
  }
}
