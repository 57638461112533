import { World } from "./World"
import { WebGLRenderTarget, OrthographicCamera } from "three"
import CameraControls from "../vendor/camera-controls"

/**
 * 何も描画しないワールド
 */
export class EmptyWorld implements World {
  public readonly id = "entrance"

  public readonly renderTarget: WebGLRenderTarget
  public readonly camera: OrthographicCamera

  constructor(private canvas: HTMLCanvasElement) {
    this.renderTarget = new WebGLRenderTarget(
      canvas.offsetWidth,
      canvas.offsetHeight
    )

    this.camera = this.initCamera()
  }

  static async init(canvas: HTMLCanvasElement): Promise<EmptyWorld> {
    await new Promise(o => setTimeout(o, 1000))
    return new EmptyWorld(canvas)
  }

  initCamera(): OrthographicCamera {
    return new OrthographicCamera(1, 1, 1, 1, 0.01, 10)
  }

  initCameraControls(): CameraControls {
    return new CameraControls(this.camera, this.canvas)
  }

  dispose() {}
  reset() {}
  async start() {}
  escape() {}

  async enterWorld() {}
  async leaveWorld() {}

  resize() {}
  onClick() {}
  onMouseMove() {}
  loop() {}
  move() {}
  setMoving() {}
  resetMoving() {}

  setEnabled(): void {}
  render(): void {}
}
