export const config = {
  HALL_FRUSTUM_SIZE: 30,
  HALL_CAMERA_DISTANCE: 100,

  HALL_CAMERA_POS_X: 0,
  HALL_CAMERA_POS_Y: 40,
  HALL_CAMERA_POS_Z: 52,

  HALL_CAMERA_TARGET_X: 0,
  HALL_CAMERA_TARGET_Y: 0,
  HALL_CAMERA_TARGET_Z: 0,

  HALL_TEXT_ZOOM_THRESHOLD: 1.2,
  HALL_CELL_SIZE: 6,
  HALL_MIN_ZOOM: 1,
  HALL_MIN_ZOOM_SP: 1.5,

  HALL_CAMERA_MIN_X: -50,
  HALL_CAMERA_MAX_X: 50,
  HALL_CAMERA_MIN_Z: 2.2,
  HALL_CAMERA_MAX_Z: 102,

  BOOTH_CAMERA_DISTANCE: 100,
  BOOTH_CAMERA_ROT_X: -Math.PI / 4,
  BOOTH_CAMERA_ROT_Y: -Math.PI / 5,
}
