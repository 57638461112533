import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import * as css from "./GameCanvas.module.scss"
import { Game } from "../webgl/Game"

import { useEvents } from "../hooks"
import { useUrlForLang } from "../hooks/language"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { getLang } from "../utils/getLang"
import { isStageType, stageTypes, WorldId } from "../commonTypes"
import { useWorldId } from "../hooks/url"

/**
 * WebGL描画用のcanvasを持つコンポーネント。
 * Gameの初期化および破棄を行う。
 */
export const GameCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)
  const worldId = useWorldId()
  const [initialWorldId] = useState<WorldId>(worldId) // マウント時のworldIdを記憶
  const urlForLang = useUrlForLang()

  useEffect(() => {
    // vendor詳細モーダルを開く
    const openVendor = (boothId: string, lang: string = `ja`) => {
      const langDir = lang === `ja` ? "" : "/en"
      navigate(`${langDir}/exhibitors/${boothId}`, {
        state: { modal: true, id: boothId },
      })
    }

    const changeWorld = (worldId: WorldId) => {
      // URLを変更
      if (worldId === "entrance") {
        navigate(urlForLang("/"))
      } else if (isStageType(worldId)) {
        navigate(urlForLang(`/${worldId}/`))
      }
      events.emit("changeWorld", worldId)
    }

    events.on("openVendor", openVendor)
    events.on("requestChangeWorld", changeWorld)

    return () => {
      events.off("openVendor", openVendor)
      events.off("requestChangeWorld", changeWorld)
    }
  }, [events, urlForLang])

  useEffect(() => {
    let game: Promise<Game>
    if (canvasRef.current !== null) {
      game = Game.init(events, canvasRef.current, initialWorldId)
    }

    return () => {
      if (game) {
        game.then(g => g.dispose())
      }
    }
  }, [canvasRef, initialWorldId])

  // 3D シーンのロード時に LOADED を DISPATCH すれば、 index.js 側でローディングレイヤーを削除します
  // https://github.com/hgw/vabf/issues/300
  // Game の load イベントと Action を変換
  useEffect(() => {
    const onLoad = (loaded: boolean, world?: string) => {
      dispatch({ type: "WEBGL_LOADED", payload: { loaded, world } })
    }
    events.on("loaded", onLoad)
    return () => {
      events.off("loaded", onLoad)
    }
  }, [events])

  return <canvas className={css.venue} ref={canvasRef} />
}
