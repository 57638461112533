import CSV from "papaparse"

export type BoothLayout = {
  index: number
  exhibitorId: number
  pos: [number, number, number]
  angle: string // 'A' | 'B' | 'C' | 'D' | 'E';
  material: string // 'Wood' | 'Pipe';
  composition: string
}

const materialHash: Record<string, string> = {
  1: "Wood",
  2: "Pipe",
}

const compositionHash: Record<string, string> = {
  O: "1",
  A: "V2",
  B: "H2",
  C: "V3",
  D: "H3",
}

function isVec3(x: number[]): x is [number, number, number] {
  return x.length === 3
}

export const loadCsv = async (
  path: string
): Promise<Record<number, BoothLayout>> => {
  const csv = (await fetch(path).then(r => r.text())).trim()
  const rows: any = CSV.parse(csv, { header: true }).data

  // const csv: any = await new Promise((resolve, reject) => {
  //   CSV.parse(path, {
  //     download: true,
  //     // header: true,
  //     complete: resolve,
  //     error: reject,
  //   })
  // })
  // console.log(csv)

  const result: Record<number, BoothLayout> = {}

  for (const row of rows) {
    const posStr = row["Booth_Location"]
    const pos = posStr
      .substr(1, posStr.length - 2)
      .split(/, /)
      .map(c => parseFloat(c))
    if (!isVec3(pos)) {
      continue
    }

    const material = materialHash[row["wall_material"]]
    if (!material) {
      continue
    }

    const composition = compositionHash[row["composition"]]
    if (!composition) {
      continue
    }

    const exhibitorId = parseInt(row["id"])

    result[exhibitorId] = {
      index: parseInt(row["Booth_Index"]),
      exhibitorId,
      pos,
      angle: row["Angle"],
      material,
      composition,
    }
  }

  return result
}
