import carrot from "./01_Carrot.json"
import garlic from "./02_Garlic.json"
import onion from "./03_Onion.json"
import potato from "./04_Potato.json"
import radish from "./05_Radish.json"
import turnip from "./06_Turnip.json"

export type CowsJSON = {
  id: string
  center: [number, number, number]
  images: number[]
}

export const cowsForStage: Record<string, CowsJSON[]> = {
  CARROT: carrot as CowsJSON[],
  GARLIC: garlic as CowsJSON[],
  ONION: onion as CowsJSON[],
  POTATO: potato as CowsJSON[],
  RADISH: radish as CowsJSON[],
  TURNIP: turnip as CowsJSON[],
} as const
