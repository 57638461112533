import {
  Mesh,
  Vector3,
  CircleBufferGeometry,
  MeshBasicMaterial,
  Camera,
  SphereBufferGeometry,
  Box3,
  Sphere,
  PlaneBufferGeometry,
  Texture,
  Object3D,
} from "three"
import { GameState, SceneObject } from "../../types"
import { TextMesh } from "../../TextMesh"
import TWEEN from "@tweenjs/tween.js"
import { Exhibitor } from "../../../api"
import { isDebugMultiBooth } from "../../../utils/isDebug"
import { decodeHTMLEntities } from "../../../utils/decodeHTMLEntities"
import { Haribote } from "./Haribote"
import { BoothLayout } from "./csv"
import { getBoundingSphereOfMesh } from "../../utils"

const CIRCLE_GEOMETRY = new CircleBufferGeometry(0.4, 16)
const SPHERE_GEOMETRY = new SphereBufferGeometry(1)
const PROFILE_CLICKER_GEOMETRY = new PlaneBufferGeometry(1, 1)

const INVISIBLE_MATERIAL = new MeshBasicMaterial()
INVISIBLE_MATERIAL.visible = false

export class Booth implements SceneObject {
  static repository: Record<string, Booth> = {}
  private originalY: number

  static findById(id: string): Booth | undefined {
    return Booth.repository[id]
  }

  private constructor(
    public readonly id: string,
    public readonly exhibitor: Exhibitor,
    public readonly mesh: Mesh,
    private board: Object3D,
    private haribote: Haribote,
    private children: { [name: string]: Mesh },
    public readonly name: TextMesh
  ) {
    Booth.repository[id] = this
    this.originalY = this.mesh.position.y
  }

  static async init(
    exhibitor: Exhibitor,
    iconTexture: Texture,
    wallTextures: Texture[],
    tableTextures: Texture[],
    lang: "ja" | "en",
    stageFileDir: string,
    layoutData: BoothLayout,
    board: Object3D
  ): Promise<Booth> {
    const root = new Mesh()
    const name = `Booth ${exhibitor.id}`
    root.name = name

    // ハリボテを入れる
    const haribote = await Haribote.init(
      stageFileDir,
      layoutData,
      board,
      wallTextures,
      tableTextures
    )
    root.add(haribote.mesh)

    // compo.meshの位置を計算
    const bbox = new Box3()
    bbox.setFromObject(haribote.mesh)

    const profile = new Mesh()
    profile.name = "Profile"
    profile.position.x += 1
    profile.position.y += 0.2

    // Icon
    const iconGeometry = CIRCLE_GEOMETRY
    const iconMaterial = new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: iconTexture,
      // map: Loader.loadTexture(exhibitor.icon),
    })
    const icon = new Mesh(iconGeometry, iconMaterial)
    icon.scale.set(0.8, 0.8, 0.8)
    icon.name = name
    profile.add(icon)

    // Name
    const boothName = decodeHTMLEntities(
      lang === "ja" ? exhibitor.name : exhibitor.name_en
    )
    const nameText = isDebugMultiBooth()
      ? `${exhibitor.id}: ${boothName}` // デバッグモードではIDを表示する
      : boothName
    const nameLabel = await TextMesh.init(nameText, "Booth", { width: 920 })
    nameLabel.mesh.name = name
    nameLabel.mesh.scale.set(0.005, 0.005, 0.005)
    nameLabel.mesh.position.add(new Vector3(0.5, -0.1, 0))
    nameLabel.mesh.rotateX(Math.PI)
    profile.add(nameLabel.mesh)

    // 名前クリック用オブジェクト
    const profileClicker = new Mesh(PROFILE_CLICKER_GEOMETRY)
    profileClicker.visible = false
    const profileBox = new Box3()
    profileBox.setFromObject(profile)

    const profileInfo = new Vector3()
    profileBox.getCenter(profileInfo)
    profileClicker.position.add(profileInfo).sub(profile.position)

    profileBox.getSize(profileInfo)
    profileClicker.scale.set(profileInfo.x, profileInfo.y, profileInfo.z)
    profileClicker.name = name
    profile.add(profileClicker)

    root.add(profile)

    // クリックしやすいように見えないオブジェクトを置いておく
    const s = getBoundingSphereOfMesh(board as Mesh)
    const dummyClicker = new Mesh(SPHERE_GEOMETRY)
    dummyClicker.scale.setScalar(s.radius)
    dummyClicker.name = name
    dummyClicker.visible = false
    dummyClicker.position.set(
      s.center.x - layoutData.pos[0],
      s.center.y - layoutData.pos[2],
      s.center.z + layoutData.pos[1]
    )
    root.add(dummyClicker)

    root.position.set(layoutData.pos[0], layoutData.pos[2], -layoutData.pos[1])

    return new Booth(
      exhibitor.id.toString(),
      exhibitor,
      root,
      board,
      haribote,
      {
        profile,
        icon,
      },
      nameLabel
    )
  }

  update(state: GameState, camera: Camera) {
    this.children["profile"].rotation.setFromRotationMatrix(camera.matrix)
    this.haribote.update(state, camera)
  }

  dispose() {
    this.mesh.remove()
  }

  private animation: any | undefined

  async scale(
    newScale: number,
    duration: number = 0,
    easing?: (x: number) => number
  ): Promise<void> {
    this.animation?.stop()

    if (duration === 0) {
      this.mesh.scale.set(newScale, newScale, newScale)
      this.board.scale.set(newScale, newScale, newScale)
      return
    }

    const currentScale = this.mesh.scale
    const tween = new TWEEN.Tween({
      x: currentScale.x,
      y: currentScale.y,
      z: currentScale.z,
    })
      .to({ x: newScale, y: newScale, z: newScale }, duration)
      .easing(easing ?? TWEEN.Easing.Cubic.InOut)
      .onUpdate(({ x, y, z }) => {
        this.mesh.scale.set(x, y, z)
        this.board.scale.set(x, y, z)
        this.mesh.position.setY(this.originalY * y)
        this.board.position.setY(this.originalY * y)
      })

    const promise = new Promise<void>(resolve =>
      tween.onComplete(() => resolve())
    )

    this.animation = tween
    tween.start()

    return promise
  }

  /**
   * コンポジション部分のBoundingSphereを得る。
   * プロフィール部分は無視されるので注意。
   */
  getBoundingSphereOfComposition(): Sphere {
    return getBoundingSphereOfMesh(this.mesh)
  }

  mouseOver() {
    this.scale(1.05, 200)
    // this.name.changeMaterial("BoothMouseOver", 0xff0000)
    this.haribote.toggleAnimation(true)
  }

  mouseLeave() {
    // this.name.changeMaterial("Booth", 0x222222)
    this.scale(1, 200)
    this.haribote.toggleAnimation(false)
  }
}
