// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carrot-index-en-tsx": () => import("./../../../src/pages/carrot/index.en.tsx" /* webpackChunkName: "component---src-pages-carrot-index-en-tsx" */),
  "component---src-pages-carrot-index-tsx": () => import("./../../../src/pages/carrot/index.tsx" /* webpackChunkName: "component---src-pages-carrot-index-tsx" */),
  "component---src-pages-exhibitors-index-en-tsx": () => import("./../../../src/pages/exhibitors/index.en.tsx" /* webpackChunkName: "component---src-pages-exhibitors-index-en-tsx" */),
  "component---src-pages-exhibitors-index-tsx": () => import("./../../../src/pages/exhibitors/index.tsx" /* webpackChunkName: "component---src-pages-exhibitors-index-tsx" */),
  "component---src-pages-front-page-tsx": () => import("./../../../src/pages/FrontPage.tsx" /* webpackChunkName: "component---src-pages-front-page-tsx" */),
  "component---src-pages-garlic-index-en-tsx": () => import("./../../../src/pages/garlic/index.en.tsx" /* webpackChunkName: "component---src-pages-garlic-index-en-tsx" */),
  "component---src-pages-garlic-index-tsx": () => import("./../../../src/pages/garlic/index.tsx" /* webpackChunkName: "component---src-pages-garlic-index-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-desk-index-en-tsx": () => import("./../../../src/pages/information-desk/index.en.tsx" /* webpackChunkName: "component---src-pages-information-desk-index-en-tsx" */),
  "component---src-pages-information-desk-index-tsx": () => import("./../../../src/pages/information-desk/index.tsx" /* webpackChunkName: "component---src-pages-information-desk-index-tsx" */),
  "component---src-pages-onion-index-en-tsx": () => import("./../../../src/pages/onion/index.en.tsx" /* webpackChunkName: "component---src-pages-onion-index-en-tsx" */),
  "component---src-pages-onion-index-tsx": () => import("./../../../src/pages/onion/index.tsx" /* webpackChunkName: "component---src-pages-onion-index-tsx" */),
  "component---src-pages-potato-index-en-tsx": () => import("./../../../src/pages/potato/index.en.tsx" /* webpackChunkName: "component---src-pages-potato-index-en-tsx" */),
  "component---src-pages-potato-index-tsx": () => import("./../../../src/pages/potato/index.tsx" /* webpackChunkName: "component---src-pages-potato-index-tsx" */),
  "component---src-pages-radish-index-en-tsx": () => import("./../../../src/pages/radish/index.en.tsx" /* webpackChunkName: "component---src-pages-radish-index-en-tsx" */),
  "component---src-pages-radish-index-tsx": () => import("./../../../src/pages/radish/index.tsx" /* webpackChunkName: "component---src-pages-radish-index-tsx" */),
  "component---src-pages-translation-example-index-en-tsx": () => import("./../../../src/pages/translation-example/index.en.tsx" /* webpackChunkName: "component---src-pages-translation-example-index-en-tsx" */),
  "component---src-pages-translation-example-index-tsx": () => import("./../../../src/pages/translation-example/index.tsx" /* webpackChunkName: "component---src-pages-translation-example-index-tsx" */),
  "component---src-pages-turnip-index-en-tsx": () => import("./../../../src/pages/turnip/index.en.tsx" /* webpackChunkName: "component---src-pages-turnip-index-en-tsx" */),
  "component---src-pages-turnip-index-tsx": () => import("./../../../src/pages/turnip/index.tsx" /* webpackChunkName: "component---src-pages-turnip-index-tsx" */),
  "component---src-templates-exhibitor-template-en-js": () => import("./../../../src/templates/ExhibitorTemplate.en.js" /* webpackChunkName: "component---src-templates-exhibitor-template-en-js" */),
  "component---src-templates-exhibitor-template-js": () => import("./../../../src/templates/ExhibitorTemplate.js" /* webpackChunkName: "component---src-templates-exhibitor-template-js" */)
}

