import { EventEmitter } from "events"
import { BoothWorld } from "./BoothWorld"

export class GarlicWorld extends BoothWorld {
  public readonly id = "garlic"

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<GarlicWorld> {
    const opts = await BoothWorld.initScene("GARLIC", "02_Garlic")
    return new GarlicWorld(events, canvas, opts)
  }
}
