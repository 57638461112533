import { EventEmitter } from "events"
import { BoothWorld } from "./BoothWorld"

export class PotatoWorld extends BoothWorld {
  public readonly id = "potato"

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<PotatoWorld> {
    const opts = await BoothWorld.initScene("POTATO", "04_Potato")
    return new PotatoWorld(events, canvas, opts)
  }
}
