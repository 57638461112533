import { Mesh } from "three"
import TWEEN, { Tween } from "@tweenjs/tween.js"
import { MovePageParams } from "../../../commonTypes"

/** kanban.glb内のメッシュ名と広告IDの対応 */
export const nodateToKanbanId: Record<string, string> = {
  727: "727",
  ArtSticker: "artsticker",
  Inuuniq: "inuuniq",
  MOT: "mot",
  RentalSign: "wanted",
  SABF: "shabf",
  ShibaParkH: "parkhotel",
  ZOZO: "zozo",
}

/** 看板クリック時に表示する外部サイト遷移モーダルの内容 */
export const kanbanParams: Record<string, MovePageParams> = {
  Info: {
    url: "https://youtu.be/lpA6lcHcktY",
    title: "HOW TO PLAY the TABF Online Venue",
    description: "オンライン会場の楽しみ方をご案内します。",
    label: "開く",
  },
  SABF: {
    url: "https://shanghaiartbookfair.com/",
    title: "上海アートブックフェア",
    description: "",
    label: "開く",
  },
  TABF2019: {
    url: "https://tokyoartbookfair.com/exhibitors/",
    title: "TABF 2021出展者一覧",
    description: "TOKYO ART BOOK FAIR2021 出展者一覧はこちらです。",
    label: "開く",
  },
  VABFYura: {
    url: "https://2020.virtualartbookfair.com/",
    title: "VABF2020",
    description: "昨年開催されたVABF2020の会場をご覧いただけます。",
    label: "開く",
  },
  KansenBoushi: {
    url: "https://tokyoartbookfair.com/news/16089/",
    title: "感染防止徹底宣言",
    description: "新型コロナウイルス感染拡大防止にご協力ください！",
    label: "開く",
  },
  WARP: {
    url: "",
    title: "WARP to ???",
    description:
      "ランダムに選んだオンライン出展者のページにワープ！どのページに飛ぶかはおたのしみ....",
    label: "ワープする",
    eventName: "warpToRandomBooth",
  },
}

/** 野立看板、A看板を管理するクラス */
export class Kanban {
  private animation: Tween<{ t: number }> | undefined

  constructor(
    public readonly mesh: Mesh,
    public readonly id: string,
    public readonly isNodate: boolean
  ) {}

  scale(newScale: number, duration: number = 0): void {
    this.animation?.stop()

    if (duration === 0) {
      this.mesh.scale.set(newScale, newScale, newScale)
      return
    }

    // x, y, z全て同じ値と仮定する
    const currentScale = this.mesh.scale.x

    this.animation = new TWEEN.Tween({ t: currentScale })
      .to({ t: newScale }, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(({ t }) => {
        this.mesh.scale.set(t, t, t)
      })
      .start()
  }
}
