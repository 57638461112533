import { EventEmitter } from "events"
import { BoothWorld } from "./BoothWorld"

export class TurnipWorld extends BoothWorld {
  public readonly id = "turnip"

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<TurnipWorld> {
    const opts = await BoothWorld.initScene("TURNIP", "06_Turnip")
    return new TurnipWorld(events, canvas, opts)
  }
}
