import {
  Camera,
  IUniform,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  ShaderMaterial,
} from "three"
import { CowsJSON } from "../../cows"
import { Loader } from "../../Loader"
import { GameState, SceneObject } from "../../types"

const PLANE_SIZE = 3.2
const PLANE_GEOMETRY = new PlaneBufferGeometry(PLANE_SIZE, PLANE_SIZE * 0.5) // 2:1比率

const vertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const fragmentShader = `
    varying vec2 vUv;
    uniform sampler2D tex0;
    uniform sampler2D tex1;
    uniform int index;

    void main() {
        if (index == 0) {
            gl_FragColor = texture2D(tex0, vUv);
        } else {
            gl_FragColor = texture2D(tex1, vUv);
        }
    }
    `

function rand(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export class Cow implements SceneObject {
  private nextMove = Date.now() / 1000 + rand(2, 3)

  private constructor(
    public readonly mesh: Mesh,
    private uniforms: Record<string, IUniform>
  ) {}

  static init(cowJSON: CowsJSON): Cow {
    const textures = cowJSON.images.map(f =>
      Loader.loadTexture(`/images/cows_compressed/${f}`)
    )

    const uniforms = {
      index: { value: 0 },
      tex0: { value: textures[0] },
      tex1: { value: textures[1] },
      isPlaying: { value: false },
    }
    const material = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader,
      fragmentShader,
      transparent: true,
      depthTest: false,
    })
    material.name = `Cow ${cowJSON.id}`

    const mesh = new Mesh(PLANE_GEOMETRY, material)
    mesh.name = `Cow ${cowJSON.id}`

    const p = cowJSON.center
    mesh.position.set(p[0], p[2], -p[1])

    return new Cow(mesh, uniforms)
  }

  update(game: GameState, camera: Camera) {
    // メッシュ全体をカメラに向かせる
    this.mesh.rotation.copy(camera.rotation)

    const now = Date.now() / 1000
    if (now > this.nextMove) {
      this.uniforms["index"].value = (this.uniforms["index"].value + 1) % 2
      this.nextMove = now + rand(0.2, 1.5)
    }
  }

  dispose() {
    this.mesh.remove()
  }
}
