import axios from "axios"
import { forEach } from "lodash"

export type ExhibitorCategory = Readonly<{
  id: string
  no: string
  name_ja: string
  name_en: string
}>

export type ExhibitorGenre = Readonly<{
  id: string
  name_ja: string
  name_en: string
}>

export type ExhibitorTag = Readonly<{
  id: number
  name: string
}>

type BoothType = "normal" | "multi"

type BoothComposition = "A" | "B" | "C" | "D" | "E" | "F" | "G"

export type Exhibitor = Readonly<{
  id: number
  name: string
  name_en: string
  name_kana: string
  country: string
  icon: string
  category: ExhibitorCategory
  genre: ExhibitorGenre[]
  booth_type: BoothType | null
  composition: BoothComposition | null
  table_num: number
  table: string[]
  table_s: string[]
  table_sp: string[]
  wall_num: number
  wall: string[]
  wall_s: string[]
  wall_sp: string[]
  message: string[]
  tag: ExhibitorTag[]
  searchWords: string
  stage: string
  initial: string
  initial_en: string
  payment: number[]
}>

function kanaToHira(str: string) {
  return str.replace(/[\u30a1-\u30f6]/g, function (match) {
    var chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

// 複数回呼ばれることがあるのでキャッシュしておく
let exhibitorsCache: Promise<Exhibitor[]> | undefined

export async function loadExhibitors(): Promise<Exhibitor[]> {
  if (exhibitorsCache) {
    return exhibitorsCache
  }

  exhibitorsCache = (async function () {
    const res = await axios.get(
      "https://d3lm2i23uzukol.cloudfront.net/exibitor/all.json"
    )
    const exhibitors = res.data.exhibitors

    // 整形
    forEach(exhibitors, (exhibitor, index) => {
      // 検索用の文字列を登録
      exhibitor.searchWords =
        exhibitor.name + exhibitor.name_en + exhibitor.name_kana

      for (const genre of exhibitor.genre) {
        exhibitor.searchWords += genre.name_ja + genre.name_en
      }

      for (const tag of exhibitor.tag) {
        exhibitor.searchWords += tag.name_ja + tag.name_en
      }

      // 検索用にキーを充実させる
      if (!exhibitor.name_kana) {
        exhibitor.name_kana = exhibitor.name_en
      }

      exhibitor.initial = exhibitor.name_kana.charAt(0)
      // 濁点をとる
      exhibitor.initial = exhibitor.initial.normalize("NFD")[0]

      // カタカナをひらがなに変換
      exhibitor.initial = kanaToHira(exhibitor.initial)

      exhibitor.initial_en = exhibitor.name_en.charAt(0)

      exhibitor.searchWords = exhibitor.searchWords.toUpperCase()
    })
    return exhibitors
  })()

  return exhibitorsCache
}

export async function loadViewCount(): Promise<number> {
  try {
    const res = await axios.get(
      "https://d3lm2i23uzukol.cloudfront.net/log/access_log/index.json"
    )
    return res.data.total
  } catch (e) {
    return 0
  }
}
