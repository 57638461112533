import { useLocation } from "@reach/router"
import { WorldId } from "../commonTypes"

function getWorldId(pathname: string): WorldId {
  if (/^\/(en\/)?$/.test(pathname)) {
    return "entrance"
  }
  if (/^\/(en\/)?radish\/$/.test(pathname)) {
    return "radish"
  }
  if (/^\/(en\/)?carrot\/$/.test(pathname)) {
    return "carrot"
  }
  if (/^\/(en\/)?onion\/$/.test(pathname)) {
    return "onion"
  }
  if (/^\/(en\/)?potato\/$/.test(pathname)) {
    return "potato"
  }
  if (/^\/(en\/)?turnip\/$/.test(pathname)) {
    return "turnip"
  }
  if (/^\/(en\/)?garlic\/$/.test(pathname)) {
    return "garlic"
  }

  return "other"
}

/**
 * 現在のURLに対応するワールドIDを返すHook
 */
export const useWorldId = (): WorldId => {
  const location = useLocation()
  return getWorldId(location.pathname)
}
