import { useLocation } from "@reach/router"
import { Language } from "../commonTypes"

export function useLang(): Language {
  const location = useLocation()
  return /^\/en/.test(location.pathname) ? "en" : "ja"
}

export function useUrlForLang(): (url: string) => string {
  const lang = useLang()
  const langDir = lang === "ja" ? "" : "/en"

  return function urlForLang(url: string) {
    return `${langDir}/${url}`
  }
}
