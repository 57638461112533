import {
  AmbientLight,
  Clock,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OrthographicCamera,
  PlaneBufferGeometry,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three"
import { World } from "./World"
import { config } from "../config"
import { SceneObject, GameState } from "../types"
import CameraControls from "../vendor/camera-controls"
import { EventEmitter } from "events"
import { Booth } from "./Booth/Booth"
import { Kanban, kanbanParams, nodateToKanbanId } from "./Booth/Kanban"
import { TextMesh } from "../TextMesh"
import TWEEN from "@tweenjs/tween.js"
import { Exhibitor, loadExhibitors } from "../../api"
import isMobile from "is-mobile"
import { Loader } from "../Loader"
import { MouseWatcher } from "../MouseWatcher"
import { getLang } from "../../utils/getLang"
import { MoveDir } from "../Game"
import {
  getBoundingSphereOfMesh,
  getRandomBGM,
  getTextureRecord,
  getTexturesRecord,
} from "../utils"
import { cowsForStage } from "../cows"
import { Cow } from "./Booth/Cow"
import noroshiData from "./Booth/noroshi.json"

import { loadCsv } from "./Booth/csv"
import { WorldId } from "../../commonTypes"

function getMinZoom(): number {
  return isMobile() ? config.HALL_MIN_ZOOM_SP : config.HALL_MIN_ZOOM
}

/** Elastic.Outよりちょっとだけ緩やかなイージング  */
function looseElastic(x: number): number {
  if (x === 0) {
    return 0
  }
  if (x === 1) {
    return 1
  }
  return 1 - Math.exp(x * -7) * Math.cos(x * 4 * Math.PI)
}

type ClickTarget = ClickBooth | ClickKanban | ClickNoroshi

type ClickBooth = {
  type: "BOOTH"
  booth: Booth
}
type ClickKanban = {
  type: "KANBAN"
  kanban: Kanban
}
type ClickNoroshi = {
  type: "NOROSHI"
  mesh: Mesh
}

type StageName = "CARROT" | "GARLIC" | "ONION" | "POTATO" | "RADISH" | "TURNIP"

type BoothWorldOpts = {
  scene: Scene
  objects: SceneObject[]
  booths: Booth[]
  kanbans: Kanban[]
  noroshis: Mesh[]
}

export abstract class BoothWorld implements World {
  public readonly id: WorldId = "other" // BoothWorldはそのまま使ってはいけません

  private enabled = true
  private raycaster = new Raycaster()
  private clock = new Clock()

  public readonly renderTarget: WebGLRenderTarget
  public readonly camera: OrthographicCamera
  protected cameraControls: CameraControls
  private bgmTimer: NodeJS.Timeout | undefined

  protected state: GameState = {
    boothId: undefined,
    boothFocus: 0,
  }

  private scene: Scene
  private objects: SceneObject[]
  private booths: Booth[]
  private kanbans: Kanban[]
  private noroshis: Mesh[]

  protected constructor(
    private events: EventEmitter,
    private canvas: HTMLCanvasElement,
    opts: BoothWorldOpts
  ) {
    this.scene = opts.scene
    this.objects = opts.objects
    this.booths = opts.booths
    this.kanbans = opts.kanbans
    this.noroshis = opts.noroshis

    this.renderTarget = new WebGLRenderTarget(
      canvas.offsetWidth,
      canvas.offsetHeight
    )

    this.camera = this.initCamera()
    this.cameraControls = this.initCameraControls()

    this.events.on("leaveVendor", this.leaveVendor)
    this.events.on("cancelMovePage", this.focusOut)
    this.events.on("warpToRandomBooth", this.focusRandomBooth)

    this.reset()
  }

  static async initScene(
    stageName: StageName,
    stageFileDir: string
  ): Promise<BoothWorldOpts> {
    const scene = new Scene()

    // Lighting
    const ambientLight = new AmbientLight(0xeeeeee)
    scene.add(ambientLight)

    // 画像、アセットをロード
    const isPC = !isMobile()
    const stageDir = stageName.toLowerCase()
    const [
      exhibitors,
      iconTextures,
      wallTextures,
      tableTextures,
      background,
      layouts,
      boards,
      kanbans,
    ] = await Promise.all([
      BoothWorld.getExhibitors(stageName),
      getTextureRecord("icon", stageDir),
      getTexturesRecord(isPC ? "wall" : "wallSp", stageDir),
      getTexturesRecord(isPC ? "table" : "tableSp", stageDir),
      this.initBackground(scene, stageFileDir), // 背景
      loadCsv(`/stages/${stageFileDir}/layout.csv`), // ハリボテ生成用データ
      this.initBoards(stageFileDir, scene), // 出展者画像メッシュ
      this.initKanbans(stageFileDir, scene), // 看板メッシュ
    ])

    // ブースを生成
    const lang = getLang()
    const boothsPromise: Promise<Booth>[] = []
    for (const exhibitor of exhibitors) {
      const layoutData = layouts[exhibitor.id]

      // レイアウト情報が見つからない場合はエラー表示する
      if (!layoutData) {
        console.error("Layout data not found for booth:", exhibitor.id)
        continue
      }

      const board = boards[layoutData.exhibitorId]
      if (!board) {
        continue
      }
      board.visible = true

      boothsPromise.push(
        Booth.init(
          exhibitor,
          iconTextures[exhibitor.id],
          wallTextures[exhibitor.id] ?? [],
          tableTextures[exhibitor.id] ?? [],
          lang,
          stageFileDir,
          layoutData,
          board
        )
      )
    }

    const booths = await Promise.all(boothsPromise)
    booths.forEach(b => {
      b.scale(0, 0)
      scene.add(b.mesh)
    })

    // 牛を表示
    const cowsJSON = cowsForStage[stageName]
    const cows: Cow[] = []
    for (const cowJSON of cowsJSON) {
      const cow = Cow.init(cowJSON)
      scene.add(cow.mesh)
      cows.push(cow)
    }

    // 狼煙
    const noroshis: Mesh[] = []
    const noroshiForStage = noroshiData[stageName]
    for (const noroshiData of noroshiForStage) {
      const noroshi = new Mesh(new PlaneBufferGeometry())
      noroshi.name = "Noroshi"
      noroshi.visible = false

      const p = noroshiData.position
      noroshi.position.set(p[0], p[2], -p[1])
      const s = noroshiData.scale
      noroshi.scale.set(s[0], s[1], s[2])

      noroshi.rotateX(-Math.PI / 2)

      scene.add(noroshi)
      noroshis.push(noroshi)
    }

    return {
      scene,
      objects: [...booths, ...cows],
      booths,
      kanbans,
      noroshis,
    }
  }

  static async initBackground(
    scene: Scene,
    stageFileDir: string
  ): Promise<void[]> {
    const tile = 4
    const dirname = isMobile() ? "bg_compressed_mobile" : "bg_compressed"

    const promises: Promise<void>[] = []
    for (let x = 0; x < tile; x++) {
      for (let y = 0; y < tile; y++) {
        promises.push(
          (async () => {
            const texture = await Loader.loadTextureAsync(
              `/stages/${stageFileDir}/${dirname}/bg-${x}-${y}.jpg`
            )

            const mesh = new Mesh(
              new PlaneBufferGeometry(100 / tile, 100 / tile),
              new MeshBasicMaterial({ map: texture })
            )

            mesh.position.set(
              (x - (tile - 1) / 2) * (100 / tile),
              0,
              (y - (tile - 1) / 2) * (100 / tile)
            )

            mesh.rotateX(-Math.PI / 2)
            scene.add(mesh)
          })()
        )
      }
    }

    return Promise.all(promises)
  }

  static async initBoards(
    stageFileDir: string,
    scene: Scene
  ): Promise<Record<string, Object3D>> {
    const stage = await Loader.loadGLTF(
      `/stages/${stageFileDir}/stage.glb`,
      undefined
    )
    scene.add(stage)

    const boards: Record<string, Object3D> = {}
    for (const c of stage.children) {
      const [, exhibitorId] = c.name.split("_")
      if (exhibitorId) {
        boards[parseInt(exhibitorId)] = c
        c.visible = false // 使われてない可能性もあるので、一旦隠しておく
      }
    }

    return boards
  }

  /** 看板データをロード (野立看板、A看板) */
  static async initKanbans(
    stageFileDir: string,
    scene: Scene
  ): Promise<Kanban[]> {
    const kanbansModel = await Loader.loadGLTF(
      `/kanbans/${stageFileDir}/kanbans.glb`,
      undefined
    )
    scene.add(kanbansModel)

    const kanbans: Kanban[] = []
    kanbansModel.traverse(o => {
      const mesh = o as Mesh
      if (!mesh.isMesh) {
        return
      }

      const mat = mesh.material as MeshBasicMaterial
      mat.transparent = true

      if (mesh.name.includes("NodateKanban")) {
        const nodateType = mesh.name.split("_").pop() ?? ""
        mesh.name = `Kanban NodateKanban ${
          nodateToKanbanId[nodateType] ?? "unknown"
        }`

        kanbans.push(new Kanban(mesh, nodateToKanbanId[nodateType], true))
      } else if (mesh.name.includes("AKanban")) {
        const [, type] = mesh.name.replace("AKanban_", "").split(/_/g)
        mesh.name = `Kanban AKanban ${type}`

        kanbans.push(new Kanban(mesh, type, false))
      }
    })

    return kanbans
  }

  setEnabled(isEnabled: boolean): void {
    this.enabled = isEnabled
    this.cameraControls.enabled = isEnabled
  }

  static async getExhibitors(stageName: string): Promise<Exhibitor[]> {
    // 出展者データをロードし、シャッフルする
    let exhibitors = await loadExhibitors()

    // ステージ名で絞り込む
    exhibitors = exhibitors.filter(e => e.stage === stageName)

    // テスト出展者は除外する
    // TODO: 不要になったら消す
    const ignoredBoothIds = [13, 593, 915]
    exhibitors = exhibitors.filter(e => !ignoredBoothIds.includes(e.id))

    return exhibitors
  }

  initCamera(): OrthographicCamera {
    const frustumSize = config.HALL_FRUSTUM_SIZE
    const aspect = this.canvas.offsetWidth / this.canvas.offsetHeight
    const w2 = (frustumSize * aspect) / 2
    const h2 = frustumSize / 2
    const camera = new OrthographicCamera(-w2, w2, h2, -h2, 0.01, 1000)

    return camera
  }

  initCameraControls(): CameraControls {
    const cameraControls = new CameraControls(this.camera, this.canvas)
    cameraControls.mouseButtons = {
      left: CameraControls.ACTION.TRUCK,
      right: CameraControls.ACTION.NONE,
      middle: CameraControls.ACTION.NONE,
      wheel: CameraControls.ACTION.ZOOM,
    }
    cameraControls.touches = {
      one: CameraControls.ACTION.TOUCH_TRUCK,
      two: CameraControls.ACTION.TOUCH_ZOOM,
      three: CameraControls.ACTION.NONE,
    }

    // カメラの動きを制限する
    cameraControls.minZoom = getMinZoom()
    cameraControls.maxZoom = 10

    cameraControls.dampingFactor = 0.07 // 気持ち速くする

    return cameraControls
  }

  dispose() {
    this.events.off("leaveVendor", this.leaveVendor)
    this.events.off("cancelMovePage", this.focusOut)
    this.events.off("warpToRandomBooth", this.focusRandomBooth)

    for (const o of this.objects) {
      o.dispose()
    }
  }

  async start(): Promise<void> {
    if (document.visibilityState !== "visible") {
      // タブが非表示の場合は表示されるのを待ってから続きをおこなう
      const start = () => {
        if (document.visibilityState === "visible") {
          document.removeEventListener("visibilitychange", start)
          this.enterWorld()
        }
      }
      document.addEventListener("visibilitychange", start)
    } else {
      await this.enterWorld()
    }

    MouseWatcher.isEnabled = true
  }

  async showBooths(): Promise<void> {
    const interval = 30

    // interval間隔でboothを表示する
    const boothCount = this.booths.length
    let i = 0

    return new Promise(resolve => {
      const loop = () => {
        if (i >= boothCount) {
          return resolve()
        }

        const booth = this.booths[i++]
        booth.scale(1, 600, looseElastic)

        // 中央に近いブースのみアニメーションする
        // if (booth.mesh.position.length() < 50) {
        // }

        setTimeout(loop, interval)
      }

      loop()
    })
  }

  reset() {
    this.resetMoving()

    // カメラを初期位置に移動
    this.cameraControls.zoomTo(getMinZoom(), false)
    this.cameraControls.setLookAt(
      config.HALL_CAMERA_POS_X,
      config.HALL_CAMERA_POS_Y,
      config.HALL_CAMERA_POS_Z,
      config.HALL_CAMERA_TARGET_X,
      config.HALL_CAMERA_TARGET_Y,
      config.HALL_CAMERA_TARGET_Z,
      false
    )
  }

  resize(width: number, height: number) {
    const frustumSize = config.HALL_FRUSTUM_SIZE
    const aspect = width / height
    this.camera.left = (-frustumSize * aspect) / 2
    this.camera.right = (frustumSize * aspect) / 2
    this.camera.top = frustumSize / 2
    this.camera.bottom = -frustumSize / 2
    this.camera.updateProjectionMatrix()

    const dpr = window.devicePixelRatio
    this.renderTarget.setSize(width * dpr, height * dpr)
  }

  loop(): void {
    if (!this.enabled) {
      return
    }
    this.state.boothFocus += this.state.boothId !== undefined ? 0.01 : -0.01
    this.state.boothFocus = Math.min(Math.max(this.state.boothFocus, 0), 1)

    // Move objects
    for (const o of this.objects) {
      o.update(this.state, this.camera)
    }

    // Update TextFade
    const zoom = (this.camera as OrthographicCamera).zoom
    const showText = config.HALL_TEXT_ZOOM_THRESHOLD < zoom
    TextMesh.fade("Booth", showText)

    // Move camera
    const delta = this.clock.getDelta()
    const hasControlsUpdated = this.cameraControls.update(delta)

    this.limitCamera()
  }

  render(renderer: WebGLRenderer): void {
    if (!this.enabled) {
      return
    }
    renderer.render(this.scene, this.camera)
  }

  getTargetUnderMouse(x: number, y: number): ClickTarget | undefined {
    this.raycaster.setFromCamera(new Vector2(x, y), this.camera)

    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    )

    // Find booth
    const boothMesh = intersects.find(i => i.object.name.includes("Booth"))
      ?.object as Mesh
    const boothId = boothMesh?.name.split(" ")[1]
    const booth = boothId && Booth.findById(boothId)
    if (booth) {
      return {
        type: "BOOTH",
        booth,
      }
    }

    // Find Kanban
    const kanbanMesh = intersects.find(i => i.object.name.includes("Kanban"))
      ?.object as Mesh
    const kanban = this.kanbans.find(a => a.mesh === kanbanMesh)
    if (kanban) {
      return {
        type: "KANBAN",
        kanban,
      }
    }

    // Find Noroshi
    const noroshiMesh = intersects.find(i => i.object.name === "Noroshi")
      ?.object as Mesh
    const noroshi = this.noroshis.find(a => a === noroshiMesh)
    if (noroshi) {
      return {
        type: "NOROSHI",
        mesh: noroshi,
      }
    }
  }

  private lastBoothUnderMouse: Booth | undefined
  private lastAdUnderMouse: Kanban | undefined

  onMouseMove(x: number, y: number): void {
    const target = this.getTargetUnderMouse(x, y)

    const booth = target?.type === "BOOTH" && target.booth
    if (booth) {
      if (this.lastBoothUnderMouse !== booth) {
        this.lastBoothUnderMouse?.mouseLeave()
        booth.mouseOver()

        this.lastBoothUnderMouse = booth
      }

      // マウスカーソルを更新する
      if (this.state.boothId === undefined) {
        document.body.style.cursor = "pointer"
      }
    } else {
      if (this.lastBoothUnderMouse) {
        this.lastBoothUnderMouse.mouseLeave()
        this.lastBoothUnderMouse = undefined
      }
    }

    const kanban = target?.type === "KANBAN" && target.kanban
    if (kanban) {
      document.body.style.cursor = "pointer"
      this.lastAdUnderMouse?.scale(1.03, 100)
      this.lastAdUnderMouse = kanban
    } else {
      this.lastAdUnderMouse?.scale(1, 100)
      this.lastAdUnderMouse = undefined
    }

    const noroshi = target?.type === "NOROSHI" && target.mesh
    if (noroshi) {
      document.body.style.cursor = "pointer"
    }

    if (!booth && !kanban && !noroshi) {
      document.body.style.cursor = ""
    }
  }

  onClick(x: number, y: number): void {
    // カメラの矯正移動中は何もしない
    if (!this.cameraControls.enabled) {
      return
    }

    const target = this.getTargetUnderMouse(x, y)
    if (target?.type === "BOOTH") {
      this.focusBooth(target.booth)
    } else if (target?.type === "KANBAN") {
      this.focusKanban(target.kanban)
    } else if (target?.type === "NOROSHI") {
      this.focusNoroshi(target.mesh)
    }
  }

  escape() {
    this.events.emit("requestLeaveVendor")
  }

  async enterWorld(prevWorldId?: string) {
    setTimeout(() => {
      this.showBooths() // awaitしない
    }, 700)

    const isPC = !isMobile()
    const minZoom = getMinZoom()

    this.cameraControls.enabled = false
    this.cameraControls.minZoom = 0

    await new Promise(resolve => {
      new TWEEN.Tween({ t: 2.5 })
        .to({ t: minZoom }, 1500)
        .easing(TWEEN.Easing.Exponential.Out)
        .onUpdate(({ t }) => this.cameraControls.zoomTo(t, false))
        .onComplete(resolve)
        .start()
    })

    this.camera.zoom = minZoom
    this.cameraControls.minZoom = minZoom
    this.cameraControls.enabled = true

    // ランダムなBGMを再生
    this.events.emit("playBGM", getRandomBGM())
    const interval = isMobile() ? 3 : 2 // PCなら2分間隔、モバイルなら3分間隔
    this.bgmTimer = setInterval(() => {
      this.events.emit("playBGM", getRandomBGM())
    }, interval * 60 * 1000)
  }

  async leaveWorld(nextWorldId: string) {
    // BGM自動再生を停止
    if (this.bgmTimer) {
      clearInterval(this.bgmTimer)
    }

    for (const b of this.booths) {
      this.scene.remove(b.mesh)
    }
  }

  moveToVendor(id: string): void {
    const booth = this.booths.find(b => b.id === id)
    if (booth) {
      this.focusBooth(booth)
    }
  }

  leaveVendor = (): void => {
    this.focusOut();
    this.events.emit("playSE", `se/12_a`)
    this.state.boothId = undefined
  }

  focusOut = (): void => {
    const zoom = getMinZoom()
    this.cameraControls.minZoom = zoom
    this.cameraControls.zoomTo(zoom, true)
  }

  private focusMesh(
    mesh: Mesh,
    opts?: {
      zoomScale?: number
      lock?: boolean
      offset?: Vector3
    }
  ) {
    const zoomScale = opts?.zoomScale ?? 1.3
    const lock = opts?.lock ?? true

    document.body.style.cursor = ""

    const s = getBoundingSphereOfMesh(mesh)
    const objectPos = mesh.position.clone()

    // サイドバーを考慮し、フォーカスをズラす
    objectPos.x -= 0.5

    if (opts?.offset) {
      objectPos.add(opts.offset)
    }

    // カメラ位置を計算
    const cameraDist = Math.sqrt(
      config.HALL_CAMERA_POS_Y ** 2 + config.HALL_CAMERA_POS_Z ** 2
    )
    const cameraDir = new Vector3(0, 0, -1)
      .applyEuler(this.camera.rotation)
      .normalize()
    const cameraPos = objectPos
      .clone()
      .sub(cameraDir.multiplyScalar(cameraDist))

    // カメラを移動し、オブジェクトに視点を合わせる
    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      objectPos.x,
      objectPos.y,
      objectPos.z,
      true
    )

    // BoundingBoxからズーム倍率を計算し、ズームする
    const diameter = s.radius * zoomScale // かなり寄り気味にする
    const width = this.camera.right - this.camera.left
    const height = this.camera.top - this.camera.bottom
    const zoom = Math.min(width / diameter, height / diameter)
    this.cameraControls.zoomTo(zoom, true)

    if (lock) {
      this.cameraControls.minZoom = zoom
    }
  }

  private focusKanban(kanban: Kanban) {
    document.body.style.cursor = ""

    if (kanban.isNodate) {
      this.focusMesh(kanban.mesh)
      this.events.emit("talkToVendor", `ad-${kanban.id}`) // ベンダーに話しかけるメニューを表示する
    } else {
      this.focusMesh(kanban.mesh, { zoomScale: 4, lock: false })

      const moveParams = kanbanParams[kanban.id]
      if (moveParams) {
        this.events.emit("requestMovePage", moveParams)
      }
    }

    switch (kanban.id) {
      case "Info":
        this.events.emit("playSE", `se/5_a`)
        break

      case "KansenBoushi":
        this.events.emit("playSE", `se/4_a`)
        break

      case "WARP":
        this.events.emit("playSE", `se/8_a`)
        break

      case "VABFYura":
        this.events.emit("playSE", `se/6_a`)
        break

      default:
        this.events.emit("playSE", `se/3_a`)
        break
    }
  }

  private focusBooth(booth: Booth) {
    this.state.boothId = booth.id
    document.body.style.cursor = ""

    this.focusMesh(booth.mesh)

    this.events.emit("talkToVendor", booth.id) // ベンダーに話しかけるメニューを表示する
    this.events.emit("playSE", `se/11_a`) // SEを鳴らす
  }

  private focusNoroshi(mesh: Mesh) {
    document.body.style.cursor = ""

    this.focusMesh(mesh, {
      zoomScale: 1.8,
      lock: false,
      offset: new Vector3(0, 0, mesh.scale.y * 0.4),
    })

    this.events.emit("requestMovePage", {
      url: "https://tokyoartbookfair.com/events/",
      title: "TABF EXHIBITORS' EVENTS",
      description: "TABF会期中に出展者が開催するイベント一覧",
      label: "開く",
    })
  }

  focusRandomBooth = () => {
    const index = Math.floor(Math.random() * 100) % this.booths.length
    const booth = this.booths[index]
    this.focusBooth(booth)
  }

  private isMoving: Record<MoveDir, boolean> = {
    left: false,
    right: false,
    up: false,
    down: false,
  }
  private moveVelocity: Record<MoveDir, number> = {
    left: 0,
    right: 0,
    up: 0,
    down: 0,
  }

  setMoving(dir: MoveDir, isMoving: boolean) {
    this.isMoving[dir] = isMoving
  }

  resetMoving() {
    this.isMoving["left"] = false
    this.isMoving["right"] = false
    this.isMoving["up"] = false
    this.isMoving["down"] = false
    this.moveVelocity["left"] = 0
    this.moveVelocity["right"] = 0
    this.moveVelocity["up"] = 0
    this.moveVelocity["down"] = 0
  }

  move(deltaTime: number) {
    for (const key of Object.keys(this.moveVelocity) as MoveDir[]) {
      let velocity = this.moveVelocity[key]
      velocity = Math.min(
        Math.max(velocity + (this.isMoving[key] ? 0.2 : -0.1), 0),
        1
      )
      this.moveVelocity[key] = velocity
      this.moveByDir(key, velocity * deltaTime)
    }
  }

  private moveByDir(dir: MoveDir, speed: number) {
    // TODO: 表示範囲外への移動は無視する

    if (dir === "left") {
      this.cameraControls.truck(-9 * speed, 0, true)
    } else if (dir === "right") {
      this.cameraControls.truck(9 * speed, 0, true)
    } else if (dir === "up") {
      this.cameraControls.truck(0, -18 * speed, true)
    } else if (dir === "down") {
      this.cameraControls.truck(0, 18 * speed, true)
    }
  }

  setCameraPos(x: number, y: number, z: number): void {
    this.camera.position.set(x, y, z)

    // 単にcamera.positionをセットするだけだとCameraControls内部の値とズレてしまうので
    // setLookAtを呼んで辻褄をあわせる必要がある……
    const target = this.camera.position.clone().sub(new Vector3(0, 40, 52))
    this.cameraControls.setLookAt(
      this.camera.position.x,
      this.camera.position.y,
      this.camera.position.z,
      target.x,
      target.y,
      target.z,
      false
    )
  }

  /**
   * カメラの移動範囲を制限する。
   * CameraControlのboundaryだと範囲外に飛び出してしまった時の挙動が微妙なので自作している
   */
  limitCamera(): void {
    // y位置は固定
    this.camera.position.y = config.HALL_CAMERA_POS_Y

    const frustumSize = config.HALL_FRUSTUM_SIZE / this.camera.zoom
    const aspect = this.canvas.width / this.canvas.height

    const marginX = (frustumSize * aspect) / 2
    const minX = config.HALL_CAMERA_MIN_X + marginX + 1
    const maxX = config.HALL_CAMERA_MAX_X - marginX - 1

    // カメラはz方向に傾いているため、角度を考慮する必要がある
    const marginZ = frustumSize / 2 / Math.cos((52 / 180) * Math.PI)
    const minZ = config.HALL_CAMERA_MIN_Z + marginZ + 0.2
    const maxZ = config.HALL_CAMERA_MAX_Z - marginZ - 0.2

    let x: number | undefined
    let z: number | undefined

    if (this.camera.position.x < minX) {
      x = minX + 0.001
    } else if (this.camera.position.x > maxX) {
      x = maxX - 0.001
    }

    if (this.camera.position.z < minZ) {
      z = minZ + 0.001
    } else if (this.camera.position.z > maxZ) {
      z = maxZ - 0.001
    }

    if (typeof x !== "undefined" || typeof z !== "undefined") {
      this.setCameraPos(
        x ?? this.camera.position.x,
        config.HALL_CAMERA_POS_Y,
        z ?? this.camera.position.z
      )
    }
  }
}
