import {
  TextureLoader,
  Mesh,
  RawShaderMaterial,
  Font,
  DoubleSide,
  PlaneBufferGeometry,
  Vector3,
  Texture,
} from "three"
import { createTextGeometry } from "./vendor/three-bmfont-text"
const loadFont = require("load-bmfont")
import { createMSDFShader } from "./vendor/three-bmfont-text/shaders/msdf"

let getTextWidthCanvas: HTMLCanvasElement | undefined

/** 文字列のサイズを計算する */
function getTextWidth(text: string): number {
  const canvas =
    getTextWidthCanvas ??
    (getTextWidthCanvas = document.createElement("canvas"))

  const context = canvas.getContext("2d")
  if (!context) {
    return 0
  }

  // Universは使えないが、大まかなサイズが分かれば良い
  context.font = "76px sans-serif"
  const metrics = context.measureText(text)
  return metrics.width
}

function clean(str: string): string {
  let s = str.replace(/[\u0000-\u001F\u007F-\u009F]/g, "") // 制御文字を取り除く

  // 個別対応シリーズ
  if (s === "EDITIONS SALON DU SALON") {
    return "EDITIONS\nSALON DU\nSALON"
  }
  if (s.includes("LEE KAN KYO")) {
    return s.replace(/LEE KAN KYO\s*/, "LEE KAN KYO\n")
  }
  if (s.includes("Praun &")) {
    return s.replace("Praun &", "Praun &\n")
  }
  if (s.includes("ART BOOK CO-OP")) {
    return s.replace("富士フイルムビジネス", " 富士フイルム\nビジネス")
  }
  if (s.includes("(石川和人")) {
    return s.replace("(石川和人", "\n(石川和人")
  }
  if (s.includes("KaleidoscopeBooks")) {
    return s.replace("KaleidoscopeBooks", "KaleidoscopeBooks\n")
  }

  // 改行が不要そうならそのまま返す
  if (getTextWidth(s) < 920) {
    return s
  }

  // 改行が必要そうなら禁則処理を行う
  return s
    .replace(/[\(（]/g, s => `\n${s}`) // 前に改行を入れる
    .replace(/(\s*[\/\+×&、。])\s*/g, s => `${RegExp.$1}\n`) // 後に改行を入れる
}

type CustomTextGeometry = ReturnType<typeof createTextGeometry>

type MaterialId = "Booth" | "Foyer" | "BoothMouseOver" | "Counter" | "Other"

type TextMeshMaterials = Readonly<{
  textMaterial: RawShaderMaterial
}>

type TextMeshOptions = Readonly<{
  color?: number
  width?: number
}>

export class TextMesh {
  static materials: Partial<Record<MaterialId, Promise<TextMeshMaterials>>> = {}
  static fontPngCache: Promise<Texture> | undefined
  static fontJsonCache: Promise<unknown> | undefined
  static isWebGL2: boolean = false

  private constructor(
    public mesh: Mesh,
    private textGeometry: CustomTextGeometry
  ) {}

  static preload() {
    TextMesh.getOrLoadFont(`/fonts/UniversNext_Hiragino_NotoCJK.json`)
    TextMesh.fontPngCache = new TextureLoader().loadAsync(
      "/fonts/UniversNext_Hiragino_NotoCJK.png"
    )
  }

  static async init(
    text: string,
    materialId: MaterialId,
    opts?: TextMeshOptions
  ): Promise<TextMesh> {
    const color = opts?.color ?? 0x222222
    const width = opts?.width ?? 1280

    const font = await TextMesh.getOrLoadFont(
      `/fonts/UniversNext_Hiragino_NotoCJK.json`
    )
    const geometry = createTextGeometry({
      width,
      align: "left",
      font: font,
    })
    geometry.update(clean(text))

    const materials = await TextMesh.getOrCreateMaterial(materialId, color)

    const mesh = new Mesh(geometry as any, materials.textMaterial)
    mesh.renderOrder = 1

    const bbSize = new Vector3()
    geometry.computeBoundingBox()
    geometry.boundingBox!.getSize(bbSize)

    return new TextMesh(mesh, geometry)
  }

  static getOrLoadFont = (path: string): Promise<unknown> => {
    if (!TextMesh.fontJsonCache) {
      TextMesh.fontJsonCache = new Promise((resolve, reject) => {
        loadFont(path, function (err: Error, font: Font) {
          if (err) {
            return reject(err)
          }
          resolve(font)
        })
      })
    }

    return TextMesh.fontJsonCache
  }

  static async getOrCreateMaterial(
    materialId: MaterialId,
    color: number
  ): Promise<TextMeshMaterials> {
    let mat = TextMesh.materials[materialId]
    if (!mat) {
      mat = TextMesh.createMaterial(color)
      TextMesh.materials[materialId] = mat
    }
    return mat
  }

  private static async createMaterial(color: number) {
    if (!TextMesh.fontPngCache) {
      TextMesh.fontPngCache = new TextureLoader().loadAsync(
        "/fonts/UniversNext_Hiragino_NotoCJK.png"
      )
    }
    const texture = await TextMesh.fontPngCache
    const textMaterial = new RawShaderMaterial(
      createMSDFShader(TextMesh.isWebGL2, {
        map: texture,
        transparent: true,
        color: color,
        side: DoubleSide,
        negate: false,
      })
    )
    textMaterial.depthTest = false

    return { textMaterial }
  }

  static async fade(materialId: MaterialId, show: boolean): Promise<void> {
    const mat = await TextMesh.materials[materialId]
    if (mat && !mat.textMaterial.uniformsNeedUpdate) {
      const { textMaterial } = mat
      const opacity =
        textMaterial.uniforms["opacity"].value + (show ? 0.03 : -0.03)

      textMaterial.uniforms["opacity"].value = Math.min(Math.max(opacity, 0), 1)
      textMaterial.uniformsNeedUpdate = true
    }
  }

  static setIsWebGL2(value: boolean): void {
    TextMesh.isWebGL2 = value
  }

  updateText(text: string): void {
    this.textGeometry.update(clean(text))

    const bbSize = new Vector3()
    this.textGeometry.computeBoundingBox()
    this.textGeometry.boundingBox!.getSize(bbSize)
  }

  async changeMaterial(materialId: MaterialId, color: number): Promise<void> {
    const mat = await TextMesh.getOrCreateMaterial(materialId, color)
    this.mesh.material = mat.textMaterial
  }
}
