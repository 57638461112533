import { EventEmitter } from "events"
import { Howl } from "howler"

const BGM_VOLUME = 0.15

export class AudioPlayer {
  private isEnabled = false
  private cache: Record<string, Promise<Howl>> = {}
  private currentBGM: Howl | undefined
  private currentPlayId: number | undefined

  constructor(private events: EventEmitter) {
    events.on("playBGM", this.playBGM)
    events.on("stopBGM", this.stopBGM)
    events.on("playSE", this.playSE)
    events.on("toggleAudio", this.toggleAudio)

    // this.load("foyer_world/bgm_entrance", true)
  }

  private async load(name: string, loop: boolean): Promise<Howl> {
    if (!this.cache[name]) {
      this.cache[name] = new Promise(resolve => {
        const bgm = new Howl({ src: [`/sounds/${name}.mp3`], loop })
        bgm.once("load", () => {
          resolve(bgm)
        })
      })
    }
    return this.cache[name]
  }

  private playBGM = async (name: string): Promise<void> => {
    if (!this.isEnabled) {
      return
    }

    const bgm = await this.load(name, true)

    // Fade out
    if (this.currentBGM && this.currentPlayId) {
      const prevBGM = this.currentBGM
      const prevPlayId = this.currentPlayId
      prevBGM.fade(BGM_VOLUME, 0, 2000, prevPlayId)
      setTimeout(() => prevBGM.stop(prevPlayId), 2000)
    }

    // Fade in
    bgm.volume(0)
    this.currentPlayId = bgm.play()
    bgm.fade(0, BGM_VOLUME, 2000, this.currentPlayId)
    this.currentBGM = bgm
  }

  private stopBGM = async (): Promise<void> => {
    if (!this.isEnabled) {
      return
    }

    // Fade out
    if (this.currentBGM && this.currentPlayId) {
      const prevBGM = this.currentBGM
      const prevPlayId = this.currentPlayId
      prevBGM.fade(BGM_VOLUME, 0, 2000, prevPlayId)
      setTimeout(() => prevBGM.stop(prevPlayId), 2000)
    }
  }

  private isPlayingSE = false

  private playSE = async (name: string): Promise<void> => {
    if (!this.isEnabled || this.isPlayingSE) {
      return
    }

    const se = await this.load(name, false)
    se.play()

    // 重複して再生されないようにする
    this.isPlayingSE = true
    setTimeout(() => {
      this.isPlayingSE = false
    }, 100)
  }

  private toggleAudio = async (
    enabled: boolean,
    playSE = false
  ): Promise<void> => {
    this.isEnabled = enabled

    if (playSE) {
      this.playSE("se/15_a")
    }

    if (this.currentBGM && this.currentPlayId) {
      const bgm = this.currentBGM
      const playId = this.currentPlayId

      if (enabled) {
        // Fade in
        bgm.volume(0)
        this.currentPlayId = bgm.play()
        bgm.fade(0, BGM_VOLUME, 1000, playId)
      } else {
        // Fade out
        bgm.fade(BGM_VOLUME, 0, 1000, playId)
        setTimeout(() => bgm.pause(playId), 1000)
      }
    }
  }
}
