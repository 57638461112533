import { EventEmitter } from "events"
import { BoothWorld } from "./BoothWorld"

export class RadishWorld extends BoothWorld {
  public readonly id = "radish"

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<RadishWorld> {
    const opts = await BoothWorld.initScene("RADISH", "05_Radish")
    return new RadishWorld(events, canvas, opts)
  }
}
