import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  useSound: false,
  counter: 0,
  webGlLoaded: false,
  loadingWorld: undefined,
  exhibitors: null,
  accessCount: null,
  stage: null,
  exhibitor: null,
  showSearch: false,
  showGlobalMenu: false,
  showStageSelect: false,
  displayClosingRemarks: true,
}

function reducer(state, action) {
  switch (action.type) {
    case "SOUND_ON": {
      return {
        ...state,
        useSound: true,
      }
    }
    case "SOUND_OFF": {
      return {
        ...state,
        useSound: false,
      }
    }

    case "SEARCH_MODE_ON": {
      return {
        ...state,
        showSearch: true,
      }
    }

    case "SEARCH_MODE_OFF": {
      return {
        ...state,
        showSearch: false,
      }
    }

    case "STAGE_SELECT_ON": {
      return {
        ...state,
        showStageSelect: true,
      }
    }

    case "STAGE_SELECT_OFF": {
      return {
        ...state,
        showStageSelect: false,
      }
    }

    case "GLOBAL_MENU_ON": {
      return {
        ...state,
        showGlobalMenu: true,
      }
    }

    case "GLOBAL_MENU_OFF": {
      return {
        ...state,
        showGlobalMenu: false,
      }
    }

    case "EXHIBITOR_MODAL_ON": {
      return {
        ...state,
        showExhibitorModal: true,
      }
    }
    case "EXHIBITOR_MODAL_OFF": {
      return {
        ...state,
        showExhibitorModal: false,
      }
    }

    case "INCREMENT_TEST_COUNT": {
      return {
        ...state,
        counter: state.counter + 1,
      }
    }
    case "WEBGL_LOADED": {
      // console.log(`GlobalContextProvider: 3D シーンがロードされました`)
      return {
        ...state,
        webGlLoaded: action.payload.loaded,
        loadingWorld: action.payload.world,
      }
    }
    case "EXHIBITORS_INDEX_LOADED": {
      return {
        ...state,
        exhibitors: action.exhibitors,
      }
    }

    case "ACCESS_COUNT_LOADED": {
      return {
        ...state,
        accessCount: action.accessCount,
      }
    }
    case "CHROME_RECOMMENDED_ACCEPT": {
      return {
        ...state,
        browserAccept: true,
      }
    }

    case "CHANGE_STAGE": {
      return {
        ...state,
        stage: action.stage,
      }
    }

    case "CHANGE_EXHIBITOR": {
      return {
        ...state,
        exhibitor: action.exhibitor,
      }
    }

    case "CHANGE_CLOSING_REMARKS": {
      return {
        ...state,
        displayClosingRemarks: action.displayClosingRemarks,
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
